<template>
  <a class="google-button" :href="oAuthLoginUrl">
    <img class="button-icon" src="@/assets/G_icon.svg" alt="" />
    <span class="button-title">{{ text }}</span>
  </a>
</template>

<script>
import config from "@/config";
import { getCookie } from "@/cookie";

export default {
  name: "GoogleSignInButton",
  props: {
    redirectUrl: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: "Sign in with Google",
    },
    useToken: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    //api-gate-staging.eks.spartan.com
    oAuthLoginUrl() {
      const url = new URL(`${config.apiAuth}/athlete_users/google_oauth2`);
      if (this.useToken) {
        const authorization = getCookie("user");
        url.searchParams.append("token", authorization.substr(7));
      }
      const redirectUrl = new URL("/auth/conn", window.location.origin);
      redirectUrl.searchParams.append("source", this.redirectUrl);
      redirectUrl.searchParams.append("auth_type", "Google");
      url.searchParams.append("redirect_url", redirectUrl.toString());
      return url.toString();
    },
  },
};
</script>

<style lang="scss" scoped>
.google-button {
  position: relative;
  height: 50px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  text-decoration: none;

  .button-icon {
    position: absolute;
    left: 14px;
    width: 20px;
    height: 20px;
  }

  .button-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    color: black;
    text-transform: uppercase;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 75%);
  }
}
</style>
