import { mapMutations } from "vuex";

export default {
  computed: {
    externalRedirect() {
      let { shopify_account_redirect, redirect, lang, country } =
        this.$route.query;
      if (shopify_account_redirect) return shopify_account_redirect;
      if (!redirect) return "";

      // Build url
      const redirectLink = new URL(redirect);

      if (lang && !redirectLink.searchParams.has("lang"))
        redirectLink.searchParams.append("lang", lang);

      if (country && !redirectLink.searchParams.has("country"))
        redirectLink.searchParams.append("country", country);

      return redirectLink.toString();
    },
    redirectUrl() {
      if (this.externalRedirect) return this.externalRedirect;
      return `${window.location.origin}/home`;
    },
  },
  methods: {
    ...mapMutations(["setUnassignedTicketsModal"]),
    showTicketsDialog(routeName) {
      const dialogRoutes = [
        "home",
        "events",
        "results",
        "teamsList",
        "tropaion",
      ];

      if (dialogRoutes.includes(routeName))
        this.setUnassignedTicketsModal(true);
    },
    closeTicketsDialog() {
      this.setUnassignedTicketsModal(false);
    },
  },
};
