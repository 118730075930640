export default {
  computed: {
    userAgent() {
      if (!navigator.userAgent) return "";
      return navigator.userAgent.toLowerCase();
    },
    isMobile() {
      return /android|webos|iphone|ipad/i.test(this.userAgent);
    },
    isIOS() {
      return /iphone|ipod|ipad/.test(this.userAgent);
    },
    isWebView() {
      if (!this.isMobile) return false;

      let safari = /safari/.test(this.userAgent);

      let android = /android/.test(this.userAgent);
      let androidWV = /wv/.test(this.userAgent); // wv - is specific for android webview, might be an issues

      if (this.isIOS && !safari) return true;
      if (android && androidWV) return true;

      return false;
    },

    fbWevView() {
      return /FB/i.test(navigator.userAgent);
    },
  },
};
