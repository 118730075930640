<template>
  <auth-layout>
    <template v-slot:title>
      <span>{{ $t("sign_in") }}</span>
      <span>{{ $t("to_continue") }}</span>
    </template>
    <template v-slot:subtitle v-if="userExist">
      <span>{{ $t("sign_in_already_have_account_subline") }}</span>
    </template>
    <template v-slot:form>
      <v-form
        ref="form"
        class="sign-in-form"
        lazy-validation
        @submit.prevent="userSignIn"
      >
        <v-text-field
          class="form-input"
          v-model="email"
          :label="$t('email')"
          :rules="[(val) => rules.email(val) || $t('auth_form_invalid_email')]"
          validate-on-blur
          @focus.stop="hideNotification"
          id="email"
          data-testid="email"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :label="$t('password')"
          :type="'password'"
          class="form-input"
          @focus.stop="hideNotification"
          id="password"
          data-testid="password"
        >
          <template v-slot:append>
            <div class="forgot-btn" @click="goToForgot">
              {{ $t("auth_form_forgot") }}
            </div>
          </template>
        </v-text-field>

        <form-terms />

        <div class="form-buttons">
          <mem-button
            btnType="secondary-dark"
            :loading="loading"
            :disabled="disableBtn"
            type="submit"
          >
            {{ $t("sign_in_and_continue") }}
          </mem-button>
          <template>
            <span class="divider">{{ $t("or") }}</span>
            <apple-auth-button
              class="auth-button"
              :redirect-url="redirectUrl"
              :text="$t('continue_with_apple')"
            />
            <facebook-button
              class="auth-button"
              :redirect-url="redirectUrl"
              :text="$t('continue_with_facebook')"
            ></facebook-button>
            <google-sign-in-button
              v-if="!isWebView"
              class="auth-button"
              :redirect-url="redirectUrl"
              :text="$t('continue_with_google')"
            />
          </template>
        </div>
      </v-form>
    </template>
    <template v-slot:footer>
      <span>{{ $t("dont_have_account") }}</span>
      <span class="text-link" @click="goToSignUp">{{ $t("join") }}</span>
    </template>
  </auth-layout>
</template>
<script>
import { emailValidator } from "@/common/validators";
import { mapActions } from "vuex";
import deviceInfoMixin from "./deviceInfoMixin";
import mixinAuthForms from "./mixinAuthForms";
import GoogleSignInButton from "@/components/GoogleSignInButton";

export default {
  name: "FormSignIn",
  data: () => ({
    email: "",
    password: "",
    rules: {
      email: emailValidator,
    },
    userExist: false,
    loading: false,
  }),
  mixins: [deviceInfoMixin, mixinAuthForms],
  components: {
    GoogleSignInButton,
    "auth-layout": () => import("./AuthLayout.vue"),
    "mem-button": () => import("../../components/base/BaseButton.vue"),
    "facebook-button": () => import("@/components/ButtonFacebook.vue"),
    "apple-auth-button": () => import("@/components/ButtonAppleAuth.vue"),
    "form-terms": () => import("./TermsSection.vue"),
  },
  computed: {
    disableBtn() {
      return !emailValidator(this.email) || !(this.password.length > 0);
    },
  },
  methods: {
    ...mapActions(["signIn", "handleToolbarNotification"]),
    async userSignIn() {
      try {
        this.loading = true;
        let result = await this.signIn({
          data: {
            email: this.email,
            password: this.password,
          },
          redirect: this.redirectUrl,
        });
        // if (process.env.VUE_APP_ENV === "local") this.$router.push("home");
        window.location = result;
      } catch (e) {
        this.loading = false;
        // console.log(e, "error");
        if (e.multipass) this.$router.push("home");
        else {
          this.handleToolbarNotification({
            type: "warning",
            text: e.invalid_credentials
              ? "auth_form_invalid_credentials"
              : "server_error",
            isVisible: true,
            canClose: true,
          });
        }
      }
    },
    goToForgot() {
      this.$router.push({
        name: "formRequestPasswordReset",
        params: { email: this.email },
        query: this.$route.query,
      });
    },
    goToSignUp() {
      this.$router.push({ name: "formSignUp", query: this.$route.query });
    },
    hideNotification() {
      this.handleToolbarNotification({ isVisible: false });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$route.params?.email) {
        vm.userExist = true;
        vm.email = vm.$route.params?.email;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.hideNotification();
    next();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.forgot-btn {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.6;
  @include cursorPointer;
}

.sign-in-form {
  .form-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 58px;
    margin-bottom: 26px;
    @media screen and (max-width: $mobile) {
      margin-top: 44px;
      margin-bottom: 44px;
    }
    .divider {
      margin-top: 26px;
      margin-bottom: 26px;
      text-transform: uppercase;
      text-align: center;
    }
    .auth-button {
      & + .auth-button {
        margin-top: 12px;
      }
    }
  }
}

::v-deep {
  .form-footer {
    text-align: center;
  }
}
</style>
